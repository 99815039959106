import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe'
import { useLocation } from 'react-router-dom'

const Course = () => {
  const [height, setHeight] = useState('100px')
  const location = useLocation()

  useEffect(() => {
    setHeight(`${document.documentElement.clientHeight}px`)
  }, [])

  return (
    <Iframe
      id='scorm'
      frameBorder={0}
      url={location.pathname}
      width='100%'
      height={height}
    />
  )
}

export default Course
