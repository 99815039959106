import React, { useState, useEffect, useContext } from 'react'
import styled from 'styled-components'
import WBTCard from '../components/WBTCard'
import { firestore } from '../firebase'
import { CircleLoader } from 'react-spinners'
import { AuthContext } from '../auth/Auth'
import { LanguageContext } from '../App'
import { Link } from 'react-router-dom'

const StyledHome = styled.div`
  /* background-color: #f0f0f0; */
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;
  /* height: calc(100vh - 61px - 4em); */

  .intro {
    font-size: 1.1em;
    line-height: 1.5em;
  }

  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 250px));
    gap: 2em;
    width: 100%;
    padding-top: 1em;
    margin-bottom: 2em;
  }

  h2,
  h3 {
    margin-top: 2em;
  }

  a {
    color: #444444;

    &:hover {
      color: #f07e14;
    }
  }

  .level-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, 250px);
    gap: 2em;
  }

  .level-item {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
    padding: 1em;
    font-weight: bold;
    border-radius: 0.5em;
  }
`

const Home = () => {
  const [courses, setCourses] = useState([])
  const { currentUser: user } = useContext(AuthContext)

  const [language] = useContext(LanguageContext)

  useEffect(() => {
    const fetchCourses = async () => {
      const snapshot = await firestore.collection('courses').orderBy('id').get()
      const courseList = []
      snapshot.docs.forEach((doc) => courseList.push(doc.data()))
      setCourses(courseList)
    }
    fetchCourses()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div>
      <StyledHome>
        {language === 'de' && (
          <>
            <p className="intro">
              Hallo, {user.firstname},
              <br />
              <br />
              herzlich willkommen beim <strong>Continental LMS</strong>. Wir
              freuen uns auf das gemeinsame Programm und Ihr Feedback zu den
              Inhalten. Für etwaige Fragen stehen wir Ihnen gerne unter{' '}
              <a
                href="mailto:TI_HS_SM_CITT@continental.com"
                style={{ color: '#f07e14' }}
              >
                TI_HS_SM_CITT@continental.com
              </a>{' '}
              zur Verfügung.
            </p>
            <h2>Kursübersicht</h2>
            <h3>Level 1 (deutsch)</h3>
          </>
        )}
        <div className={`card-container ${courses === [] ? 'loading' : ''}`}>
          {courses === [] ? (
            <>
              <h3>Kurse werden geladen</h3>
              <CircleLoader color="#0070bb" />
            </>
          ) : (
            courses.map((course) => <WBTCard key={course.id} course={course} />)
          )}
        </div>

        <h3>Level 2 (deutsch)</h3>
        <div className="level-grid">
          <Link
            className="level-item"
            to="/courses/level2/de/bedeutung-von-industrie40-fuer-conti"
          >
            Die Bedeutung von Industrie 4.0 für Continental
          </Link>
          <Link
            className="level-item"
            to="/courses/level2/de/digital-administration"
          >
            Digitale Administration
          </Link>
          <Link className="level-item" to="/courses/level2/de/fertigung">
            Fertigung 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/hr">
            Human Relations 4.0 / Lernen 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/instandhaltung">
            Instandhaltung 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/it">
            IT 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/marketing-sales">
            Marketing & Sales 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/qm">
            Qualitätsmanagement 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/rnd">
            Forschung & Entwicklung 4.0
          </Link>
          <Link className="level-item" to="/courses/level2/de/scm">
            Supply Chain Management 4.0
          </Link>
        </div>

        {user.level === 1 && (
          <>
            <h3>Level 1 (englisch)</h3>
            <div className="level-grid">
              <Link className="level-item" to="/courses/level1/big-data">
                Big Data
              </Link>
              <Link
                className="level-item"
                to="/courses/level1/platform-economy"
              >
                Platform Economy
              </Link>
              <Link
                className="level-item"
                to="/courses/level1/business-model-innovation"
              >
                Business Model Innovation
              </Link>
              <Link className="level-item" to="/courses/level1/digital-mindset">
                Digital Mindset
              </Link>
              <Link
                className="level-item"
                to="/courses/level1/digital-transformation"
              >
                Digital Transformation
              </Link>
              <Link
                className="level-item"
                to="/courses/level1/horizontal-vertical-integration"
              >
                Horizontal and Vertical Integration
              </Link>
              <Link
                className="level-item"
                to="/courses/level1/human-machine-interaction"
              >
                Human-Machine Interaction
              </Link>
              <Link
                className="level-item"
                to="/courses/level1/internet-of-things"
              >
                Internet of Things
              </Link>
              <Link className="level-item" to="/courses/level1/leadership">
                Leadership 4.0
              </Link>
            </div>
            <h3>Level 2 (englisch)</h3>
            <div className="level-grid">
              <Link
                className="level-item"
                to="/courses/level2/digital-administration"
              >
                Digital Administration
              </Link>
              <Link
                className="level-item"
                to="/courses/level2/human-ressources"
              >
                Human Ressources .40
              </Link>
              <Link className="level-item" to="/courses/level2/it">
                IT 4.0
              </Link>
              <Link className="level-item" to="/courses/level2/maintenance">
                Maintenance 4.0
              </Link>
              <Link className="level-item" to="/courses/level2/manufacturing">
                Manufacturing 4.0
              </Link>
              <Link
                className="level-item"
                to="/courses/level2/marketing-and-sales"
              >
                Marketing and Sales 4.0
              </Link>
              <Link
                className="level-item"
                to="/courses/level2/quality-management"
              >
                Quality Management 4.0
              </Link>
              <Link
                className="level-item"
                to="/courses/level2/research-and-development"
              >
                Research and Development 4.0
              </Link>
              <Link
                className="level-item"
                to="/courses/level2/supply-chain-management"
              >
                Supply Chain Management 4.0
              </Link>
              <Link
                className="level-item"
                to="/courses/level2/meaning-of-i40-for-continental"
              >
                The Meaning of Industry 4.0 for Continental
              </Link>
            </div>
          </>
        )}
      </StyledHome>
    </div>
  )
}

export default Home
