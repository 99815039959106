import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/analytics'
import 'firebase/functions'

const firebaseConfig = {
  apiKey: 'AIzaSyCsFGgXAlCW8BcxYOwNiso-ggwxvF6lot0',
  authDomain: 'conti-lms.firebaseapp.com',
  projectId: 'conti-lms',
  storageBucket: 'conti-lms.appspot.com',
  messagingSenderId: '852442454360',
  appId: '1:852442454360:web:7533612c31e6f49b18ed2d',
  measurementId: 'G-BY5BWKY6RZ',
}

firebase.initializeApp(firebaseConfig)

export const firebaseInstance = firebase
export const auth = firebase.auth()
export const firestore = firebase.firestore()
export const storage = firebase.storage()
export const analytics = firebase.analytics()
export const functions = firebase.functions()

export const generateUserDocument = async (user, context) => {
  if (!user) return
  const userRef = firestore.doc(`users/${user.uid}`)
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    try {
      const email = auth.currentUser.email
      await userRef.set({
        firstLogin: new Date(),
        username: email,
        ...context,
      })
    } catch (error) {
      console.error('Error creating user document', error)
    }
  }

  return getUserDocument(user.uid)
}

const getUserDocument = async (uid) => {
  if (!uid) return null
  try {
    const userDocument = await firestore.doc(`users/${uid}`).get()
    return {
      uid,
      ...userDocument.data(),
    }
  } catch (error) {
    console.error('Error fetching user', error)
  }
}
