import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../App'
import { firestore } from '../firebase'

const StyledStatistics = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;

  .card {
    margin-bottom: 1rem;

    .title {
      font-weight: bold;
    }
  }

  .muted {
    color: gray;
  }
`

const Statistics = () => {
  const [language] = useContext(LanguageContext)
  const [users, setUsers] = useState(null)
  const [courses, setCourses] = useState(null)
  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    let loadedUsers = false
    let loadedCourses = false

    const fetchUsers = async () => {
      const snapshot = await firestore
        .collection('users')
        .where('participantID', '>=', 0)
        .orderBy('participantID')
        .get()
      const userList = []
      snapshot.docs.forEach((doc) => userList.push(doc.data()))
      setUsers(userList)
      loadedUsers = true
      if (loadedCourses) setIsLoaded(true)
    }
    fetchUsers()

    const fetchCourses = async () => {
      const snapshot = await firestore.collection('courses').orderBy('id').get()
      const courseList = []
      snapshot.docs.forEach((doc) => courseList.push(doc.data()))
      setCourses(courseList)
      loadedCourses = true
      if (loadedUsers) setIsLoaded(true)
    }
    fetchCourses()
  }, [])

  return (
    <StyledStatistics>
      <h1>{language === 'de' ? 'Statistiken' : 'Statistics'}</h1>
      {isLoaded && (
        <div>
          <div style={{ marginBottom: '1rem' }}>
            <div style={{ fontWeight: 'bold' }}>
              {users.length}
              {language === 'de' ? ' Teilnehmer' : ' participants'}
            </div>
            <div className='muted'>
              {users.filter((user) => user.firstLogin).length}{' '}
              {`${
                language === 'de'
                  ? 'Teilnehmer haben auf das LMS zugegriffen'
                  : 'participants accessed the LMS'
              } (${(
                (users.filter((user) => user.firstLogin).length /
                  users.length) *
                100
              ).toFixed(0)} %)`}
            </div>
          </div>
          {courses.map((course) => (
            <div key={course.id} className='card'>
              <div className='title'>
                {language === 'de' ? course.title : course.titleEn}
              </div>
              <div>
                {language === 'de' ? 'Videos gestartet: ' : 'Videos started: '}
                {
                  users.filter((user) => {
                    if (
                      course.url.substring(7) === 'modul1' ||
                      course.url.substring(7) === 'modul2' ||
                      course.url.substring(7) === 'modul3' ||
                      course.url.substring(7) === 'modul4'
                    )
                      return (
                        user.videosStarted?.includes(
                          `${course.url.substring(7)}-1`
                        ) &&
                        user.videosStarted?.includes(
                          `${course.url.substring(7)}-2`
                        ) &&
                        user.videosStarted?.includes(
                          `${course.url.substring(7)}-3`
                        )
                      )

                    return user.videosStarted?.includes(
                      `${course.url.substring(7)}-1`
                    )
                  }).length
                }{' '}
                <span className='muted'>{`(${(
                  (users.filter((user) => {
                    if (
                      course.url.substring(7) === 'modul1' ||
                      course.url.substring(7) === 'modul2' ||
                      course.url.substring(7) === 'modul3' ||
                      course.url.substring(7) === 'modul4'
                    )
                      return (
                        user.videosStarted?.includes(
                          `${course.url.substring(7)}-1`
                        ) &&
                        user.videosStarted?.includes(
                          `${course.url.substring(7)}-2`
                        ) &&
                        user.videosStarted?.includes(
                          `${course.url.substring(7)}-3`
                        )
                      )

                    return user.videosStarted?.includes(
                      `${course.url.substring(7)}-1`
                    )
                  }).length /
                    users.length) *
                  100
                ).toFixed(0)} %)`}</span>
              </div>
              <div>
                {language === 'de' ? 'Videos angesehen: ' : 'Videos watched: '}
                {
                  users.filter((user) => {
                    if (
                      course.url.substring(7) === 'modul1' ||
                      course.url.substring(7) === 'modul2' ||
                      course.url.substring(7) === 'modul3' ||
                      course.url.substring(7) === 'modul4'
                    )
                      return (
                        user.videosFinished?.includes(
                          `${course.url.substring(7)}-1`
                        ) &&
                        user.videosFinished?.includes(
                          `${course.url.substring(7)}-2`
                        ) &&
                        user.videosFinished?.includes(
                          `${course.url.substring(7)}-3`
                        )
                      )

                    return user.videosFinished?.includes(
                      `${course.url.substring(7)}-1`
                    )
                  }).length
                }{' '}
                <span className='muted'>{`(${(
                  (users.filter((user) => {
                    if (
                      course.url.substring(7) === 'modul1' ||
                      course.url.substring(7) === 'modul2' ||
                      course.url.substring(7) === 'modul3' ||
                      course.url.substring(7) === 'modul4'
                    )
                      return (
                        user.videosFinished?.includes(
                          `${course.url.substring(7)}-1`
                        ) &&
                        user.videosFinished?.includes(
                          `${course.url.substring(7)}-2`
                        ) &&
                        user.videosFinished?.includes(
                          `${course.url.substring(7)}-3`
                        )
                      )

                    return user.videosFinished?.includes(
                      `${course.url.substring(7)}-1`
                    )
                  }).length /
                    users.length) *
                  100
                ).toFixed(0)} %)`}</span>
                {/* <span className='muted'>{`(${(
                  users.filter((user) =>
                    user.videosFinished?.includes(
                      `${course.url.substring(7)}-1`
                    )
                  ).length /
                  (users.filter((user) =>
                    user.videosStarted?.includes(`${course.url.substring(7)}-1`)
                  ).length +
                    Number.EPSILON)
                ).toFixed(0)} %)`}</span> */}
              </div>
            </div>
          ))}
        </div>
      )}
    </StyledStatistics>
  )
}

export default Statistics
