import React, { useState, useEffect, useRef } from 'react'
import 'styled-components/macro'

const navItem = `
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon-button {
    --button-size: calc(var(--nav-size) * 0.5);
    width: var(--button-size);
    height: var(--button-size);
    background-color: #484a4d;
    border-radius: 50%;
    padding: 5px;
    margin: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: filter 300ms;
    color: var(--nav-text-color);

    &:hover {
      filter: brightness(1.2);
    }
    
    svg { 
      fill: var(--nav-text-color);
      color: var(--nav-text-color);
      width: 20px;
      height: 20px;
    }
    
  }
  
`

const NavItem = (props) => {
  const [open, setOpen] = useState(false)

  const closeMenu = () => setOpen(false)

  function useOutsideClicker(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  const wrapperRef = useRef(null)
  useOutsideClicker(wrapperRef)

  return (
    <li css={navItem} ref={wrapperRef} className='nav-item'>
      <div className='icon-button' onClick={() => setOpen(!open)}>
        {props.icon}
      </div>

      {open && React.cloneElement(props.children, { closeMenu })}
    </li>
  )
}

export default NavItem
