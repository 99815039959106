import React, { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { LanguageContext } from '../App'
import { firestore } from '../firebase'

const StyledAdmin = styled.div`
  --text-color: #4b4b4b;

  max-width: 1200px;
  margin: 0 auto;
  padding: 2em;

  .card {
    margin-bottom: 1rem;

    .title {
      font-weight: bold;
    }
  }

  .muted {
    color: gray;
  }

  form {
      margin: 2em auto;
      max-width: 500px;

      background-color: white;
      padding: 1em;
      border-radius: 1em;
      box-shadow: 0 0 40px -10px rgba(0, 0, 0, 0.3);
    }

    .field {
      position: relative;
      display: flex;
      flex-direction: column;
      border-bottom: 2px dashed var(--text-color);
      margin: 2rem auto .25rem;
    }

    input {
      height: 1.5em;
      border: none;
      border-radius: 0.25em;
      padding: 0;
      outline: none;
      overflow: hidden;
      margin: 0;
      width: 100%;
      background: #f5f5f5;
      color: var(--text-color);
      font-weight: bold;
      font-size: 1rem;

      // &:valid {
      //   color: yellowgreen;
      // }

      // &:invalid {
      //   color: orangered;
      // }
    }

    .label {
      color: var(--text-color);
      font-size: 1.2rem;
    }

    // Animation
    .field::after {
      content: '';
      position: relative;
      display: block;
      height: 4px;
      width: 100%;
      background: var(--primary);
      transform: scaleX(0);
      transform-origin: 0%;
      transition: transform 500ms ease;
      top: 2px;
    }

    .field:focus-within {
      border-color: transparent;

      &::after {
        transform: scaleX(1);
      }
    }

    // IE fix
    .field.focus-within {
      border-color: transparent;

      &::after {
        transform: scaleX(1);
      }
    }

    .label {
      /* z-index: -1; */
      position: absolute;
      transform: translateY(0rem);
      transform-origin: 0%;
      transition: transform 400ms;
      pointer-events: none;
    }

    .field:focus-within .label,
    .input:not(:placeholder-shown) + .label{
      transform: scale(0.8) translateY(-2rem);
    }



    // IE fix
    .js-focus-within {
      .field.focus-within .label {
        transform: scale(0.8) translateY(-2rem);
      }
      :not(.placeholder-shown) + .label {
        transform: scale(0.8) translateY(-2rem);
      }
    }

    .primary {
      padding: 0.75rem;
      background-color: var(--primary);
      color: white;
      font-size: 1.2rem;

      &:hover {
        background-color: #0083da;
        color: white;
      }
    }

    h1 {
      color: var(--text-color);
    }

    .error, .success {
      color: red;
      font-weight: bold;
      text-align: center;
      background-color: #ff000040;
      padding: 0.25em;
      border-radius: 0.25em;
      margin-top: 2em;
    }

    .success {
      color: green;
      background-color: #00ff0040;
      font-weight: normal;
      text-align: left;

      p {
        font-weight: bold;
      }
    }

    .submitButton {
      margin-top: 2em;
      width: 100%;
      border: none;
      cursor: pointer;
      border-radius: 0.25em;
      transition: background-color 0.2s;

      &:disabled {
        background-color: var(--bg-accent);
        cursor: not-allowed;
      }
    }
  }


  small {
    color: #444444;
  }

  .flex {
    display: flex;
    & * + .form-group {
      margin-left: 1rem;
    }
  }

  .form-group {
    flex: 1;
    margin-bottom: 1rem;
  }
`

// const API_URL =
//   'http://localhost:5001/conti-lms/europe-west3/courses/api/addUser'
const API_URL =
  'https://europe-west3-conti-lms.cloudfunctions.net/courses/api/addUser'

const InputField = ({ name, label, info, state }) => {
  const [inputState, setInputState] = useState('')

  useEffect(() => {
    state.setUserInfo((previous) => ({ ...previous, [name]: inputState }))
  }, [inputState])

  return (
    <div className='form-group'>
      <div className='field'>
        <input
          onChange={(e) => setInputState(e.target.value)}
          type='text'
          name={name}
          className='input'
          placeholder=' '
        />
        <label htmlFor={name} className='label'>
          {label}
        </label>
      </div>
      {info && <small>{info}</small>}
    </div>
  )
}

const Admin = () => {
  const [language] = useContext(LanguageContext)
  const [userInfo, setUserInfo] = useState(null)
  const [error, setError] = useState([])
  const [success, setSuccess] = useState([])
  const [sending, setSending] = useState(false)

  const handleAddUser = async () => {
    if (sending) return
    setSending(true)

    // Validate inputs
    setError([])
    setSuccess([])
    if (!userInfo.user)
      setError((previous) => [
        ...previous,
        language === 'de'
          ? 'Bitte geben Sie einen Benutzernamen ein.'
          : 'Please enter a username.',
      ])
    if (!userInfo.firstName)
      setError((previous) => [
        ...previous,
        language === 'de'
          ? 'Bitte geben Sie den Vornamen ein.'
          : 'Please enter the first name.',
      ])
    if (!userInfo.lastName)
      setError((previous) => [
        ...previous,
        language === 'de'
          ? 'Bitte geben Sie den Nachnamen ein.'
          : 'Please enter the last name.',
      ])

    // if (error) return setSending(false)

    try {
      // Add user
      const request = await fetch(API_URL, {
        method: 'post',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userInfo),
      })

      const response = await request.json()
      // console.log(response)
      setSending(false)
      if (response.error) return setError([response.error])
      if (!response.password && !response.accessUntil)
        return setError([response.message])
      if (response.password)
        return setSuccess([
          `
      <p>Ein Account für ${response.firstName} ${
            response.lastName
          } wurde angelegt:</p>
      <ul>
      <li>Benutzername: <strong>${response.user}</strong></li>
      <li>Passwort: <strong>${response.password}</strong></li>
      <li>Gültig bis: <strong>${new Date(
        response.accessUntil
      ).toLocaleDateString('de', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      })}</strong></li>
      </ul>`,
        ])
      if (response.accessUntil)
        return setSuccess([
          `<p>Die Zugangsberechtigung für ${response.firstname} ${
            response.lastname
          } wurde erneuert.</p><ul><li>Gültig bis: <strong>${new Date(
            response.accessUntil
          ).toLocaleDateString('de', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
          })}</strong></li></ul>`,
        ])
    } catch (error) {
      console.error(error)
      setSending(false)
    }
  }

  return (
    <StyledAdmin>
      <h1>{language === 'de' ? 'Admin' : 'Admin'}</h1>
      <div>
        <h2>
          {language === 'de' ? 'Teilnehmer hinzufügen' : 'Add participants'}
        </h2>
        <p>
          Durch das Hinzufügen eines Teilnehmers erhält dieser 30 Tage auf auf
          das LMS. Existiert noch kein Teilnehmer mit dem Benutzernamen wird
          dieser als aktiver Teilnehmer neu angelegt. Falls schon ein inaktiver
          Teilnehmer mit dem Benutzernamen existiert wird er für 30 Tage
          aktiviert.
        </p>
        {/* <form> */}
        <InputField
          name='user'
          label={language === 'de' ? 'Benutzername' : 'Username'}
          info={
            language === 'de'
              ? 'Benutzername des Teilnehmers (bspw. max.mustermann). Keine E-Mail-Addresse. Keine Leerzeichen.'
              : 'The user name of the participant (e.g. john.doe). Not his email address. No spaces.'
          }
          state={{ userInfo, setUserInfo }}
        />
        <div className='flex'>
          <InputField
            name='firstName'
            label={language === 'de' ? 'Vorname' : 'First name'}
            state={{ userInfo, setUserInfo }}
          />
          <InputField
            name='lastName'
            label={language === 'de' ? 'Nachname' : 'Last name'}
            state={{ userInfo, setUserInfo }}
          />
        </div>

        {/* </form> */}
        {error &&
          error.map((error, index) => (
            <div key={index} className='error'>
              {error}
            </div>
          ))}
        {success &&
          success.map((success, index) => (
            <div
              key={index}
              className='success'
              dangerouslySetInnerHTML={{ __html: success }}
            />
          ))}
        <button
          type='submit'
          className='submitButton primary'
          disabled={sending}
          onClick={handleAddUser}>
          {language === 'de' ? 'Teilnehmer hinzufügen' : 'Add user'}
        </button>
      </div>
    </StyledAdmin>
  )
}

export default Admin
