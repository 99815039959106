import React, { createContext, useState, useEffect, useContext } from 'react'
import { LanguageContext } from '../App'
import {
  auth,
  generateUserDocument,
  analytics,
  firebaseInstance as firebase,
  firestore,
} from '../firebase'

export const AuthContext = createContext(null)

const Auth = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null)

  const [, setLanguage] = useContext(LanguageContext)

  useEffect(() => {
    auth.onAuthStateChanged(async (userAuth) => {
      const user = await generateUserDocument(userAuth)
      setCurrentUser(user)
      userAuth && analytics.setUserId(userAuth.uid)

      if (userAuth) {
        const userData = await firestore
          .collection('users')
          .doc(userAuth.uid)
          .get()

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp()

        // Set first login
        if (!userData.data().firstLogin) {
          userData.ref.update({
            firstLogin: serverTimestamp,
          })
        }

        // Set last login
        userData.ref.update({
          lastLogin: serverTimestamp,
        })

        // Set language
        const userLanguage = userData.data().language
        if (userLanguage === 'de' || userLanguage === 'en')
          setLanguage(userLanguage)
      }
    })
  }, [setLanguage])

  return (
    <AuthContext.Provider value={{ currentUser }}>
      {children}
    </AuthContext.Provider>
  )
}

export default Auth
