import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { analytics } from '../firebase'
import { LanguageContext } from '../App'

const StyledWBTCard = styled.div`
  width: 250px;
  max-width: calc(250px - 2em);
  background-color: white;
  padding: 1em;
  border-radius: 0.5em;
  box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;

  &.upcoming {
    cursor: no-drop;
  }

  &.upcoming::before {
    content: '';
    background-color: #00000080;
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    border-radius: 0.5em;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 30px -5px rgba(0, 0, 0, 0.4);

    &.upcoming {
      transform: none;
      box-shadow: 0 0 20px -5px rgba(0, 0, 0, 0.3);
      cursor: no-drop;
    }
  }

  .image {
    width: 250px;
    height: 200px;
    background-color: lightgray;
    margin: -1em -1em 0 -1em;
    border-radius: 0.5em 0.5em 0 0;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 1em;
    color: white;
    font-weight: bold;
    text-transform: uppercase;

    &.upcoming {
      width: 250px;
      height: 200px;
      /* margin: -1em 1em 1em -1em; */
      /* padding: 1em; */
      background-color: gray;
    }

    img {
      width: 250px;
      height: 200px;
      border-radius: 0.5em 0.5em 0 0;
    }
  }

  .header {
    margin: 1em 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .title {
    color: #333;
    font-weight: bold;
  }

  .time {
    font-size: 0.9em;
    background-color: var(--primary);
    color: white;
    padding: 0.25em 0.5em;
    border-radius: 0.25em;
    flex: 0 0 auto;
    margin-left: 1em;
  }

  .description {
    color: #444;
    line-height: 1.2;
  }
`

const WBTCard = ({
  course: {
    id,
    title,
    titleEn,
    description,
    image,
    duration,
    date,
    url,
    imageWidth,
    debug,
    externalUrl,
    externalUrlEn,
  },
}) => {
  // const [imageUrl, setImageUrl] = useState(null)

  // useEffect(() => {
  //   const getImageRef = async () => {
  //     const imageRef = storage.refFromURL(image)
  //     const url = await imageRef.getDownloadURL()
  //     setImageUrl(url)
  //   }
  //   if (image) getImageRef()
  // }, [image])

  const [language] = useContext(LanguageContext)

  return externalUrl ? (
    <a
      href={language === 'de' ? externalUrl : externalUrlEn}
      target='_blank'
      rel='noopener noreferrer'
      style={{ position: 'relative' }}>
      <StyledWBTCard>
        <div className='image'>
          <img
            src={`/courses/cover/${id}.svg`}
            alt={title}
            style={{ width: `${imageWidth}px` }}
          />
        </div>
        <div className='header'>
          {language === 'de' && <div className='title'>{title}</div>}
          {language === 'en' && <div className='title'>{titleEn}</div>}
          <div className='time'>{duration} min</div>
        </div>

        {language === 'de' && <div className='description'>{description}</div>}
      </StyledWBTCard>
    </a>
  ) : (
    <Link
      to={url}
      onClick={() =>
        analytics.logEvent('select_content', {
          content_type: 'scorm',
          content_id: '1',
        })
      }>
      <StyledWBTCard>
        <div className='image'>
          <img
            src={`/courses/cover/${id}.svg`}
            alt={title}
            style={{ width: `${imageWidth}px` }}
          />
        </div>
        <div className='header'>
          {language === 'de' && <div className='title'>{title}</div>}
        </div>
        {language === 'de' && <div className='description'>{description}</div>}
      </StyledWBTCard>
    </Link>
  )
}

export default WBTCard
